.sleepModeDiv {
  background-color: black;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
}

.sleepModeIconGrid {
  align-self: center;
}

.sleepModeIcon {
  color: white;
  transform: rotate(-215deg);
  font-size: 36px !important;
}

.sleepModeText {
  color: white;
  font-size: 15px !important;
}