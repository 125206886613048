.container {
  margin-top: 20px;
  background-color: #fff;
}

.textBold {
  font-weight: bold;
}

.progressDialog {
  min-height: 15vh;
  margin: auto;
}

.hideList {
  opacity: 0;
}

.showList {
  opacity: 1;
}
