.gateway-status-container {
  background-color: rgba(205,55,15,255);
  margin-top: 5px;
  height: 50px;
}

.status-icon-container {
  align-self: center;
}

.status-icon {
  color: white;
  font-size: 32px !important;
  align-self: center;
}

.gateway-status-text {
  color: white;
  font-size: 16px !important;
}
