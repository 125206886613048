.selectLanguageLabel {
  padding: 10px;
  text-align: center;
}

.languageSelectContainer {
  margin-top: 5%;
}

.languageList {
  border: 1px solid rgba(0, 0, 0, 0.23);
  max-height: 250px;
  min-height: 250px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.btnContainer {
  padding-right: 17px;
  margin-top: 15px;
}
